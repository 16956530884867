<template>
  <div class="page-wrapper">
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant" />
        <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/tagline-fr.svg" class="tagline" alt="Tagline Tremblant" />
        <img v-else-if="$route.meta.lang === 'en'" src="@/assets/img/tagline-en.svg" class="tagline" alt="Tagline Tremblant" />
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang"
            >FR</router-link
          >
        </div>
        <a :href="$t('site.flash-mars-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant" /></a>
      <div class="mobile-cta">
        <a :href="$t('site.flash-mars-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">
      <div v-if="$route.meta.lang === 'fr'">
        <button class="cta prune" @click.prevent="goback">Retour</button><br /><br />

        <h1 class="big-title">Modalités<br>Billets de ski</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">Valables du dimanche au vendredi, du 7 au 11 mars 2022 et du 21 mars au 18 avril 2022.</li>
          <li class="regular-text">Billets non-transférables (les billets ne peuvent pas être utilisés par deux personnes différentes)</li>
        </ul>

        <h2 class="small-title">Achat et réservation</h2>
        <ul>
          <li class="regular-text">En ligne ou par téléphone au 1-888-738-1777, du 1er mars 2022, à 13 h, au 3 mars 2022, à 13 h. </li>
          <li class="regular-text">Aucune limite d’achat.</li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">Rendez-vous au Service à la clientèle, au Centre aventure du Sommet des neiges, à la billetterie (carte d’identité avec photo obligatoire) ou aux bornes d’impression dès la veille de la première journée d’utilisation.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Non remboursables.</li>
        </ul>

        <h2 class="small-title">Passeport vaccinal et preuve de vaccination</h2>
        <p class="regular-text">
          Preuve vaccinale obligatoire pour accéder aux remontées mécaniques. Pour les détenteurs de billets (Entrepôt, vente éclair, skiMax, multi-jours, autres), la vérification de la preuve vaccinale se fera en personne à l’un de nos comptoirs de billetteries. Une pièce d’identité avec photo sera requise.
        </p>
      </div>

      <div v-else-if="$route.meta.lang === 'en'">
        <button class="cta prune" @click.prevent="goback">Back</button><br /><br />

        <h1 class="big-title">Lift Tickets<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">1-day lift tickets valid from Sunday to Friday March 7 to 11, 2022, and from March 21 to April 18, 2022.</li>
          <li class="regular-text">Tickets are not transferable (tickets can not be used by 2 different persons).</li>
        </ul>

        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">Online or by phone at 1-888-738-1777 from March 1, 2022, 1 p.m., to March 3, 2022, 1 p.m. </li>
          <li class="regular-text">No purchase limit.</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">Guests must pick up their tickets at our Guest Services Desk, at the Centre Aventure in the Sommet des Neiges hotel, the ticket office (bring a valid photo ID) or ticket printing kiosks as of the day before your arrival.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Not refundable.</li>
        </ul>

        <h2 class="small-title">Vaccine passport and proof of vaccination</h2>
        <p class="regular-text">
          Proof of vaccination and photo ID are required to access the ski lifts. Day ticket holders (Ski-max, multi-day, ticket outlet, and others) will need to validate vaccination on-site. A valid photo ID is required.
        </p>
      </div>
    </section>

    <footer-bar></footer-bar>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform" />
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              v-model="time1"
              name="arrivaldate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'arrivaldate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              v-model="time2"
              name="departuredate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'departuredate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input name="adultcount" type="number" value="2" min="1" required />
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input name="childCount" type="number" value="0" required />
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')" />
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: this.$t(`site.flash-mars-meta-title`),
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: this.$t(`site.flash-mars-meta-title`) },
        {
          name: 'description',
          content: this.$t(`site.flash-mars-meta-desc`),
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: this.$t(`site.flash-mars-meta-title`) },
        { name: 'twitter:card', content: 'Tremblant' },
        {
          n: 'twitter:description',
          c: this.$t(`site.flash-mars-meta-desc`),
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`site.flash-mars-meta-title`) },
        { property: 'og:site_name', content: 'Tremblant' },
        {
          property: 'og:description',
          content: this.$t(`site.flash-mars-meta-desc`),
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://promo.tremblant.ca/vue-app-static-img/img/flash/mars.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://promo.tremblant.ca/vue-app-static-img/img/flash/mars.jpg',
        },
      ],
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null,
    }
  },
  computed: mapState('app', ['appTitle']),
  mounted() {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      this.snowscroll()
    })
  },
  methods: {
    goback() {
      return this.$router.go(-1)
    },
    table1() {
      this.toggle = true
    },
    table2() {
      this.toggle = false
    },
    showlodgingform() {
      if (this.formlodging) {
        this.formlodging = false
      } else {
        this.formlodging = true
      }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
      if (this.sidepanel) {
        this.sidepanel = false
      } else {
        this.sidepanel = true
      }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${-150 + st / 2}px`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
